import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import { Image } from "mui-image";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "../contexts/SessionContext";

function FooterLink({ url, text }: { url: string; text: string }) {
  return (
    <a href={url} target="_blank" rel="noreferrer" style={{ color: "white", textDecoration: "none", textAlign: "right" }}>
      {text}
    </a>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const { organization } = useSessionContext();

  const embedded = organization?.organization.embedded;

  return (
    <Box mt={5} py={embedded ? 2 : 4} sx={{ backgroundImage: "linear-gradient(13deg, #B0D9D9 27%, #038DAB 100%)" }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={8} display="flex" justifyContent={{ xs: "center", lg: "left" }} alignItems="center" width="100%">
            <Stack direction="row" alignItems="center">
              <Typography mr={2}>{t("layout.powered-by")}</Typography>
              <Image src="/images/dermsmart.png" width="auto" height={50} />
            </Stack>
          </Grid>

          {!embedded && (
            <Grid item lg={4} width="100%">
              <Stack spacing={1} width="100%">
                <FooterLink text={t("layout.privacy-policy")} url={t("layout.privacy-policy-url")} />
                <FooterLink text={t("layout.terms-and-conditions")} url={t("layout.terms-and-conditions-url")} />
              </Stack>

              <Typography mt={3} color="white" fontSize="0.8rem" textAlign={{ xs: "center", lg: "right" }}>
                {t("layout.copyright")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
